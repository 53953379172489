import "../../care/index.css"

import { graphql } from "gatsby"
import React from "react"
import { Trans } from "react-i18next"
import { useTranslation } from "react-i18next"

import Layout from "../../../components/Layout"
import Seo from "../../../components/Seo"
import PatternLateral from "../../../components/patternLateral/PatternLateral"

export default function IndexPage() {
  const { t } = useTranslation()

  return (
    <Layout territory={"tech"}>
      <Seo title={t("Join us")} />
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
